import { alert } from '@/assets/js/eventAnalyze.config.js'
export function alertAnalyze (str, path) {
  if (path != '/al') return
  try {
    let eventName = alert[str]()
    gtag('event', eventName) // 谷歌统计
  } catch (err) {
    console.log('The Google analysis has not been loaded yet')
  }
}

<template>
  <div
    id='alert_page'
    class="clearfix">
    <div class="nav_left f_left">
      <div class="compose">
        <button @click='showAlertBox'>{{$t('lang.compose')}}</button>
      </div>
      <div class="alert_nav">
        <ul>
          <li
            :class='{active:isShow.receive}'
            @click='switchShowContent("receive")'>
            <span></span>
            <span class='nav_text'>{{$t('lang.inbox')}} ({{num.notReadMsg}})</span>
          </li>
          <li
            :class='{active:!isShow.receive}'
            @click='switchShowContent("sent")'>
            <span></span>
            <span class='nav_text'>{{$t('lang.sent')}}</span>
          </li>
          <i
            class="f_right el-icon-edit-outline"
            @click='showAlertBox'></i>
        </ul>
      </div>
    </div>
    <div class="alert_detail f_right">
      <div class="top clearfix">
        <div
          class="sel_all f_left pointer"
          @click='selAll'
          :class='{active:isSelAll}'>
          <span class='f_left sel_icon'>{{$t('lang.selAll')}}</span>
        </div>
        <div
          class="delete_btn f_left"
          v-repeat-click='{event:deleteMessage}'>
          <span
            class='f_left'
            :class='{active:deleteActive}'>{{$t('lang.delete')}}</span>
        </div>
        <div class="page f_right">
          <Pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :pageSizes="[10, 20, 50, 100]"
            :pageSize="pageSize"
            :total="total"
            layout="prev, pageF, next, pageL, sizes, nowSize, jumper" />
        </div>
        <div
          class="totle_num f_right"
          v-if='total'>{{dataListParam.pageSize*(dataListParam.currentPageNum-1)+1}}-{{dataListParam.pageSize*dataListParam.currentPageNum}} of {{total}}</div>
        <div
          class="totle_num f_right"
          v-else>0-0 of 0</div>
      </div>
      <div
        class="message"
        v-loading='loading'
        element-loading-background="rgba(0, 0, 0, .6)">
        <ul v-show='isShow.receive'>
          <li
            class='clearfix'
            v-for='(item, index) in inBoxArr'
            :key='index'
            :class='{active:inBoxSel[item.messageId]}'
            @click='openInfoDetail(item,"inbox")'>
            <div
              class="f_left"
              style='width:3%'
              @click.stop='switchSelItem(item.messageId)'>
              <span class="sel_icon"></span>
            </div>
            <div
              class="f_left"
              style='width:97%'>
              <p class='from clearfix'>
                <span class='from_title f_left'>{{$t('lang.from')}}:</span>
                <span
                  class='email_box ellipsis f_left'
                  :class='{normal:item.type==1}'>{{item.senderName}}</span>
                <span class="f_right time">
                  {{getTime(item.timestamp)}} &nbsp; {{$t(showTimeStyle(item.timestamp))}}
                </span>
              </p>
              <p
                class='mes_title ellipsis'
                :class='{normal:item.type==1}'>{{item.title}}</p>
              <p
                class='alert_msg ellipsis'
                :class='{gray:item.type==1}'
                v-if='item.messageObj.messageStart'>
                {{item.messageObj.messageStart}}
                <span
                  v-for='(itm, idx) in item.messageObj.messageCenter'
                  :key='idx'>{{`${idx+1}.${itm.feature}`}} </span>
              </p>
              <p
                class='alert_msg ellipsis'
                :class='{gray:item.type==1}'
                v-else>{{item.messageObj.content}}</p>
            </div>
          </li>
        </ul>

        <ul v-if="!isShow.receive">
          <li
            class='clearfix'
            v-for='(item, index) in sentArr'
            :key='index'
            :class='{active: inBoxSel[item.messageId]}'
            @click='openInfoDetail(item,"sent")'>
            <div
              class="f_left"
              style='width:3%'
              @click.stop='switchSelItem(item.messageId)'>
              <span class="sel_icon"></span>
            </div>
            <div
              class="f_left"
              style='width:97%'>
              <p class='clearfix from'>
                <span class='from_title sent f_left'>{{$t('lang.to')}}:</span>
                <span class='email_box normal ellipsis f_left'>{{getReceiveMsg(item.recipientsName)}}</span>
                <span class="f_right time">
                  {{getTime(item.timestamp)}} &nbsp; {{$t(showTimeStyle(item.timestamp))}}
                </span>
              </p>
              <p class='mes_title ellipsis normal'>{{item.title}}</p>
              <p class='alert_msg ellipsis gray'>{{item.message}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <sent-alerts
      v-if='isShow.sentAlert'
      :dialogVisible.sync='isShow.sentAlert'
      :isInbox='isShow.receive'
      @updateSentMsg='switchShowContent'></sent-alerts>
    <info-detail
      :dialogVisible.sync='isShow.feature'
      :msg='currentMsg'></info-detail>
  </div>
</template>
<script>
import Outil from '@/assets/js/utils.js'
import Bus from '@/assets/js/vueBus.js'
import Pagination from '@/components/Pagination'
import SentAlerts from './sentAlerts'
import InfoDetail from './infoDetail'
import { alertAnalyze } from '@/assets/js/googleAnalyze/alert.js'

export default {
  components: {
    Pagination,
    SentAlerts,
    InfoDetail
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 10,
      isSelAll: false,
      deleteActive: false,
      inBoxSel: {},
      inBoxArr: [],
      total: null,
      loading: false,
      sent: {},
      num: {
        notReadMsg: null,
        sentNum: null
      },
      sentArr: [],
      currentMsg: {},
      isShow: {
        receive: true,
        sentAlert: false,
        feature: false
      },
      dataListParam: {
        currentPageNum: 1,
        pageSize: 10
      },
      sendHasRead: 2
    }
  },
  created () {
    this.getReceiveList()
    Bus.$on('alertNotReadMsg', data => {
      if (data > this.num.notReadMsg) this.num.notReadMsg = data
    })
  },
  methods: {
    // 全选按钮
    selAll () {
      let inBoxSel = {}
      if (this.isSelAll) {
        this.isSelAll = false
        this.deleteActive = false
        for (const key in this.inBoxSel) {
          inBoxSel[key] = false
        }
      } else {
        this.isSelAll = true
        this.deleteActive = true
        for (const key in this.inBoxSel) {
          inBoxSel[key] = true
        }
      }
      this.inBoxSel = inBoxSel
    },
    // 打开详情展示
    openInfoDetail (data, type) {
      const [first, ...rest] = type
      alertAnalyze(`alert${first.toUpperCase()}${rest.join('')}MessageClick`, '/al')
      this.isShow.feature = true
      this.currentMsg = data.messageObj || { content: data.message }
      this.currentMsg.from = type == 'inbox' ? data.senderName : data.recipientsName
      this.currentMsg.title = data.title
      this.currentMsg.time = data.timestamp
      this.currentMsg.type = type
      if (type == 'inbox') {
        if (data.type == '0' && this.num.notReadMsg > 0) this.num.notReadMsg--
        data.type = '1'
        this.updateHasReadAlert(data)
      }
    },
    // 标记为已读
    updateHasReadAlert (msg) {
      this.axios.post('/ccp/tvucc-alert/message/updateHasReadAlert', { messageId: msg.messageId }, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.status != 200 || res.data.errorCode != '0x0') {
            if (this.sendHasRead) {
              this.updateHasReadAlert(msg)
              this.sendHasRead--
              return
            }
            this.sendHasRead = 2
          } else {
            this.sendHasRead = 2
          }
        }).catch(() => {
          this.sendHasRead = 2
        })
    },
    // 单个按钮
    switchSelItem (messageId) {
      this.inBoxSel[messageId] = !this.inBoxSel[messageId]
      let inBoxSel = {}
      var allFlag = true
      var deleteFlag = false
      for (const key in this.inBoxSel) {
        inBoxSel[key] = this.inBoxSel[key]
        if (!this.inBoxSel[key]) allFlag = false
        if (this.inBoxSel[key]) deleteFlag = true
      }
      this.isSelAll = !!allFlag
      this.deleteActive = !!deleteFlag
      this.inBoxSel = inBoxSel
    },
    // 删除消息
    deleteMessage () {
      let deleteIds = []
      for (const key in this.inBoxSel) {
        if (this.inBoxSel[key]) deleteIds.push(key)
      }
      if (!deleteIds.length) return
      alertAnalyze('alertDeleteClick', '/al')
      const url = this.isShow.receive ? '/ccp/tvucc-alert/message/deleteRecevMsg' : '/ccp/tvucc-alert/message/deleteSendMsg'
      this.axios.post(url, { messageId: deleteIds.join(',') }, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          if (res.status == 200 && res.data.errorCode == '0x0') {
            this.$message.success(this.$t('lang.success'))
            this.isSelAll = false
            this.deleteActive = false
            if (this.isShow.receive) {
              this.getReceiveList()
            } else {
              this.getSentList()
            }
          } else {
            this.$message.error(res.data.errorInfo)
          }
        }).catch(() => {})
    },
    // 获取接收信息列表
    getReceiveList () {
      this.loading = true
      this.axios({
        method: 'post',
        url: '/ccp/tvucc-alert/message/getReceiveMsgList',
        data: this.dataListParam,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then(res => {
        this.loading = false
        if (res.status == 200 && res.data.errorCode == '0x0') {
          const data = res.data.result.list
          this.currentPage = data.pageNum
          this.dataListParam.currentPageNum = data.pageNum
          this.num.sentNum = res.data.result.SentNum
          this.num.notReadMsg = res.data.result.NotReadMsg
          this.total = data.total
          this.inBoxArr = data.list
          this.inBoxArr.map((v, i) => {
            try {
              v.messageObj = JSON.parse(v.message.replace(/&quot;/g, '"'))
            } catch (err) {
              v.messageObj = { content: v.message }
            }
            if (typeof v.messageObj != 'object') {
              v.messageObj = { content: v.message }
            }
          })
          this.getSelCheckbox(data.list)
          this.setPageVal(data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 分页及页码
    setPageVal (data) {
      this.total = data.total
      this.pageSize = data.pageSize
      this.currentPage = data.pageNum
    },
    // 给所有的复选框添加选中控制
    getSelCheckbox (arr) {
      this.inBoxSel = {}
      arr.map(v => {
        this.inBoxSel[v.messageId] = false
      })
    },
    // 时间转换
    getTime (inputTime) {
      inputTime = Number(inputTime)
      const nowTime = new Date().getTime()
      if (inputTime != null && nowTime != null) {
        const timeDis = nowTime - inputTime
        if (timeDis < 60 * 1000) {
          return ''
        } else if (timeDis < 2 * 60 * 1000) {
          return '1'
        } else if (timeDis < 60 * 60 * 1000) {
          return Math.floor(timeDis / (60 * 1000))
        } else if (timeDis < 2 * 60 * 60 * 1000) {
          return '1'
        } else if (timeDis < 24 * 60 * 60 * 1000) {
          return Math.floor(timeDis / (60 * 60 * 1000))
        } else {
          return ''
        }
      }
    },
    // 时间显示风格
    showTimeStyle (inputTime) {
      inputTime = Number(inputTime)
      const nowTime = new Date().getTime()
      if (inputTime != null && nowTime != null) {
        const timeDis = nowTime - inputTime
        if (timeDis < 60 * 1000) {
          return 'lang.now'
        } else if (timeDis < 60 * 60 * 1000) {
          return 'lang.minAgo'
        } else if (timeDis < 24 * 60 * 60 * 1000) {
          return 'lang.hourAgo'
        } else {
          const time = Outil.getDateTime(new Date(inputTime))
          return `${time.hour}:${time.min} ${time.month}-${time.day}-${time.year}`
        }
      }
    },
    // 转换信息
    getReceiveMsg (message) {
      if (!message) return message
      message = message.replace(/[\n]/g, '<br/>').replace(/ /g, '&nbsp;')
      // base64解码
      let transformMsg = message
      if (/^STORY-\S{16}&nbsp;/.test(transformMsg)) {
        transformMsg = message.substring(0, 28)
        const base64Decode = window.atob(message.slice(28, message.length))
        transformMsg += base64Decode
      }
      return transformMsg
    },
    // 获取sent列表
    getSentList () {
      this.loading = true
      this.axios({
        method: 'post',
        url: '/ccp/tvucc-alert/message/getSentOutMsgList',
        data: this.dataListParam,
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then(res => {
        this.loading = false
        if (res.status == 200 && res.data.errorCode == '0x0') {
          const data = res.data.result.list
          this.currentPage = data.pageNum
          this.dataListParam.currentPageNum = data.pageNum
          this.total = data.total
          this.num.sentNum = res.data.result.SentNum
          this.num.notReadMsg = res.data.result.NotReadMsg
          this.sent = data
          this.sentArr = data.list
          this.getSelCheckbox(data.list)
          this.setPageVal(data)
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 切换内容
    switchShowContent (type, update) {
      if (update === undefined) {
        const [first, ...rest] = type
        alertAnalyze(`alert${first.toUpperCase()}${rest.join('')}Open`, '/al')
      }
      const isReceive = this.isShow.receive
      if (document.body.clientWidth > 767) {
        this.isShow.receive = type == 'receive'
        if (isReceive == this.isShow.receive && !update) return // 跟上次一样
      } else {
        this.isShow.receive = type != 'receive'
        type = this.isShow.receive ? 'receive' : 'sent'
      }
      this.dataListParam.currentPageNum = 1
      this.isSelAll = false
      if (type == 'receive') {
        this.getReceiveList()
      } else {
        this.getSentList()
      }
    },
    // 处理分页及页码
    handleSizeChange (val) {
      this.pageSize = val
      this.currentPage = 1
      this.dataListParam.pageSize = this.pageSize
      this.dataListParam.currentPageNum = this.currentPage
      this.isSelAll = false
      this.deleteActive = false
      if (this.isShow.receive) this.getReceiveList()
      else this.getSentList()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.isSelAll = false
      this.deleteActive = false
      this.dataListParam.currentPageNum = this.currentPage
      if (this.isShow.receive) this.getReceiveList()
      else this.getSentList()
    },
    // 展示弹窗
    showAlertBox () {
      alertAnalyze('alertComposeOpen', '/al')
      this.isShow.sentAlert = true
    }
  }
}
</script>
<style lang="less">
.message ul::-webkit-scrollbar {
  width: 6px;
}
#alert_page {
  height: 100%;
  .sel_icon {
    background-image: url(../../assets/img/v3_selectedIcon.png);
    background-repeat: no-repeat;
    display: block;
    height: 24px;
    padding-left: 36px;
    font-size: 0.16rem;
    background-position: 6px -48px;
  }
  .nav_left {
    width: 20.5%;
    background: #333;
    height: 100%;
    padding-top: 0.4rem;
    .compose {
      font-size: 0.16rem;
      padding-bottom: 0.3rem;
      margin: 0 0.3rem 0rem 0.3rem;
      border-bottom: 2px solid #666;
      button {
        background-color: #39aa50;
        border: #39aa50 1px solid;
        border-radius: 4px;
        line-height: 0.32rem;
        width: 133.5px;
        color: #fff;
      }
    }
    .alert_nav {
      margin-top: 0.4rem;
      li {
        height: 0.5rem;
        line-height: 0.5rem;
        width: inherit;
        position: relative;
        text-align: left;
        margin: 0 0.3rem 0.2rem 0.3rem;
        font-size: 0.14rem;
        text-align: left;
        span:first-child {
          float: left;
          width: 5%;
          height: 100%;
        }
      }
      li.active {
        span:first-child {
          border-left: 5px solid #33ab4f;
        }
      }
      .nav_text {
        display: inline-block;
        padding-left: 20px;
      }
      .el-icon-edit-outline {
        display: none;
      }
    }
  }
  .alert_detail {
    text-align: left;
    width: 79.5%;
    height: 100%;
    padding: 0.6rem 1rem 0.1rem 1rem;
    .top {
      font-size: 16px;
      margin-bottom: 12px;
    }
    .sel_all.active {
      .sel_icon {
        background-position: 6px -20px;
      }
    }
    .delete_btn {
      span {
        background-image: url(../../assets/img/alert_delete_none.png);
        background-repeat: no-repeat;
        background-position: 0 -4px;
        display: block;
        padding-left: 30px;
        font-size: 16px;
        cursor: not-allowed;
        height: 24px;
        line-height: 24px;
        margin-left: 20px;
      }
      span.active {
        background-image: url(../../assets/img/alert_delete_records.png);
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .page {
      ::v-deep .paginationBox {
        margin-top: 0;
      }
    }
    .totle_num {
      margin-right: 19px;
      font-size: 14px;
      color: rgba(109, 109, 109, 1);
      height: 28px;
      line-height: 28px;
      margin-top: 8px;
    }
    .message {
      font-size: 0.14rem;
      padding-top: 0.16rem;
      border-top: 2px solid #217536;
      max-height: 730px;
      overflow-y: scroll;
      .from {
        color: #dfdfdf;
      }
      .from_title {
        width: 40px;
      }
      .from_title.sent {
        width: 23px;
      }
      ul {
        padding-right: 20px;
        -webkit-overflow-scrolling: touch;
      }
      li {
        padding: 0.12rem 0;
        padding-bottom: 2px;
        border-bottom: 1px solid #555;
      }
      li.active {
        .sel_icon {
          background-position: 6px -20px;
        }
      }
      .mes_title {
        width: 86%;
        height: 22px;
        line-height: 22px;
        font-size: 18px;
        font-weight: bold;
        color: rgba(223, 223, 223, 1);
        margin-top: 10px;
      }
      p.normal,
      span.normal {
        font-weight: normal;
      }
      .alert_msg.gray {
        color: #666;
        font-weight: normal;
      }
      .alert_msg {
        font-weight: bold;
        width: 86%;
        height: 20px;
        margin-top: 10px;
        word-break: break-all;
        word-spacing: 0.2em;
        font-size: 14px;
        color: #dfdfdf;
      }
      .time {
        margin-right: 20px;
        color: #dfdfdf;
        font-size: 14px;
      }
      .email_box {
        font-weight: 700;
        width: 75%;
        font-size: 14px;
      }
    }
  }
}
@media (max-width: 1580px) {
  #alert_page .alert_detail .message {
    .mes_title,
    .alert_msg {
      width: 82%;
    }
  }
}
@media (max-width: 1350px) {
  #alert_page .alert_detail .message {
    .from_title {
      margin-left: 20px;
    }
    .email_box {
      width: 70%;
    }
  }
}
@media (max-width: 1230px) {
  #alert_page .alert_detail {
    padding: 20px 50px;
    .message {
      .mes_title,
      .alert_msg {
        width: 83%;
      }
    }
  }
}
@media (max-width: 1192px) {
  #alert_page {
    .nav_left .alert_nav .nav_text {
      padding-left: 20px;
    }
    .alert_detail .message {
      .mes_title,
      .alert_msg {
        width: 80%;
      }
      .email_box {
        width: 60%;
      }
    }
  }
}
@media (max-width: 858px) {
  #alert_page {
    .alert_detail .totle_num {
      width: 100%;
      margin: 0;
      height: 20px;
      margin-top: 6px;
      text-align: center;
    }
    .nav_left .compose {
      margin: 0 18px;
    }
  }
}
@media (max-width: 767px) {
  #alert_page {
    .nav_left {
      // display: none;
      width: 100%;
      height: 50px;
      background: #252525;
      padding: 0;
      .compose {
        display: none;
      }
      .alert_nav {
        margin-top: 0;
        position: relative;
        li {
          text-align: center;
          display: none;
          font-size: 20px;
          .nav_text {
            padding: 0;
          }
        }
        li.active {
          display: block;
        }
        li.active:after {
          font-family: 'element-icons' !important;
          content: '\E60B';
        }
        li > span:first-child {
          display: none;
        }
        .el-icon-edit-outline {
          position: absolute;
          right: 50px;
          top: 0;
          display: block;
          height: 50px;
          line-height: 50px;
          font-size: 28px;
          font-weight: 700;
          color: #217536;
        }
      }
    }
    .alert_detail {
      width: 100%;
    }
    .alert_detail .totle_num {
      width: auto;
      height: 28px;
      margin-right: 19px;
      margin-top: 0;
    }
    .alert_detail .message {
      height: auto;
    }
  }
}
</style>

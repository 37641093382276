<template>
  <el-dialog
    :title="msg.title"
    :visible.sync="dialogVisible"
    width="999px"
    class='feature'
    :before-close="handleClose">
    <div class="content">
      <div class="title clearfix">
        <div class="f_left address">
          <span v-if='msg.type=="inbox"'>{{$t('lang.from')}}</span>
          <span v-else>{{$t('lang.to')}}</span> : {{msg.from}}</div>
        <div class="f_right">{{msg.time | DATE("MM/dd/yyyy hh:mm:ss")}}</div>
      </div>
      <div class='feature_msg'>
        <p
          class='message_start'
          v-show='msg.messageStart'>{{msg.messageStart}}</p>
        <div
          class="info"
          v-if='msg.type=="inbox" && deviceFeatureObj'>
          <div
            v-for='(item, key) in deviceFeatureObj'
            :key='key'>
            <h3 class="deviceInfo">{{item.deviceName}} / {{key}}:</h3>
            <p
              v-for='(item, index) in item.list'
              :key='index'>
              <span>{{item.nickName || item.feature}} - </span><span>{{item.time | DATE("MM/dd/yyyy hh:mm:ss")}}</span>
            </p>
          </div>
        </div>
        <pre
          class="info"
          v-else>{{msg.content}}</pre>
        <div
          class='footer'
          v-if='msg.type=="inbox"&&msg.messageCenter'>To extend these feature, please contact your sales representative or TVU Support:
          <span class='email'>support@tvunetworks.com</span>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  props: {
    dialogVisible: {
      type: Boolean
    },
    msg: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    dialogVisible: function (newVal, oldVal) {
      this.deviceFeatureObj = null
      if (this.msg.type == 'inbox' && this.msg.messageCenter && this.msg.messageCenter.length && newVal) this.handleMsg()
    }
  },
  data () {
    return {
      deviceFeatureObj: null
    }
  },
  methods: {
    handleMsg () {
      this.deviceFeatureObj = {}
      this.msg.messageCenter.forEach(v => {
        if (v.peerId) {
          const peerId = v.peerId.toUpperCase()
          if (this.deviceFeatureObj[peerId]) {
            this.deviceFeatureObj[peerId].list.push(v)
          } else {
            this.$set(this.deviceFeatureObj, peerId, { list: [v], deviceName: v.name })
          }
        }
      })
    },
    handleClose () {
      this.$emit('update:dialogVisible', false)
    },
    getDeviceInfo (item) {
      if (item.name) {
        const msg = `${item.peerId.toUpperCase()}(${item.name})`
        return msg
      } else {
        const msg = `${item.peerId.toUpperCase()}`
        return msg
      }
    }
  }
}
</script>
<style lang='less' scoped>
.feature {
  p {
    margin: 0;
  }
  text-align: left;
  ::v-deep .el-dialog {
    height: 558px;
  }
  ::v-deep .el-dialog .el-dialog__body {
    padding: 30px 16px 0;
  }
  ::v-deep .el-dialog__title {
    width: 100%;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title {
    color: #dfdfdf;
    font-size: 14px;
    margin-bottom: 15px;
    .address {
      width: 790px;
      max-height: 80px;
      overflow-y: scroll;
    }
  }
  .feature_msg {
    max-height: 338px;
    overflow-y: scroll;
    .message_start {
      margin-bottom: 24px;
    }
    .info {
      background: transparent;
      border: none;
      color: #dfdfdf;
      padding: 0;
      font-size: 14px;
      white-space: break-spaces;
      max-height: 250px;
      overflow-y: scroll;
      .deviceInfo {
        color: #33ab4f;
        font-size: 16px;
        margin: 15px 0 5px;
      }
      span {
        color: #fff;
        font-weight: normal;
        line-height: 25px;
      }
    }
  }
  .content > div,
  .content > p {
    color: #dfdfdf;
  }
  p span:first-child {
    font-weight: 700;
  }
  .footer {
    margin-top: 24px;
  }
  .email {
    color: #2f8df4;
    text-decoration: underline;
  }
  ::v-deep
    .el-dialog
    .el-dialog__header
    .el-dialog__headerbtn
    .el-icon-close:before {
    content: '\E78D';
  }
}
</style>

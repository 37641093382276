<template>
  <el-dialog
    :title="$t('lang.sentAlert')"
    :visible.sync="dialogVisible"
    width="1000px"
    class="sent_alerts"
    :before-close="handleClose">
    <div class="setal_content clearfix">
      <div class="user_list f_left">
        <div class="filter clearfix">
          <!-- bookmark -->
          <div class="filter_box f_left bookmark_box pr">
            <book-mark
              :callback="changeFilter"
              :from="{ Unclassified: true }"
              :defaultKeys="defaultBookmark"
              fromPage="al"
            ></book-mark>
          </div>
          <div
            class="filter_box f_right point pr"
            @click.stop="showFilter">
            {{$t('lang.filter')}} ({{checkedFilterList.length}})
            <span class="more"></span>
            <div
              class="filter_list"
              v-if="isShow.filter"
              style="height: auto;"
              @click="prevent($event)">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange">{{$t('lang.all')}}</el-checkbox>
              <el-checkbox-group
                v-model="checkedFilterList"
                @change="handleCheckedCitiesChange">
                <el-checkbox
                  v-for="filterItm in filterList"
                  :label="filterItm"
                  :key="filterItm">{{$t(filterItm)}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div class="list_box">
          <el-tree
            :data="groupDevicesArr"
            :props="defaultProps"
            @node-click="handleNodeClick"></el-tree>
        </div>
      </div>
      <div class="sent_detail f_right">
        <div class="sent_member clearfix pr">
          <div
            class="to address"
            ref="address">
            <div class="to_title f_left">{{$t('lang.to')}}:</div>
            <p
              class="f_left"
              v-for="(dItm, dIdx) in receiveMsgArr"
              :key="dIdx">
              <span class="f_left">{{dItm.name}}</span>
              <i
                class="el-icon-error"
                @click="cancelReceiver(dItm)"></i>
            </p>
            <input
              type="text"
              v-model="searchVal"
              ref="searchDevice"
              @keydown.stop="searchKeyDown" />
            <ul
              v-show="searchObjList.length"
              :style="{top:`${searchBox.top}px`,left:`${searchBox.left}px`}">
              <li
                class="ellipsis"
                v-for="(item, index) in searchObjList"
                :key="index"
                @click="handleNodeClick(item)">{{item.name}}</li>
            </ul>
          </div>
          <div class="to">
            <input
              style="width:100%;"
              type="text"
              class
              :placeholder="$t('lang.createTitle')"
              v-model="title"
              maxlength="255" />
          </div>
        </div>
        <div class="messageText">
          <textarea
            :style="{height:`${textareaHeight}px`}"
            :placeholder="$t('lang.alertContent')"
            maxlength="21845"
            v-model="msgContent"></textarea>
        </div>
        <div class="sent_btn f_right">
          <button @click="sendMsg">{{$t('lang.send')}}</button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import toTips from '@/assets/js/toTipsObj'
import Bus from '@/assets/js/vueBus.js'
import BookMark from '@/components/bookMark'
import { alertAnalyze } from '@/assets/js/googleAnalyze/alert.js'

export default {
  props: {
    dialogVisible: {
      type: Boolean
    },
    isInbox: {
      type: Boolean
    }
  },
  components: {
    BookMark
  },
  data () {
    return {
      defaultBookmark: {
        bookmardIds: ['all'],
        token: true,
        unClassified: true
      },
      receiveMsgArr: [], // 收件人
      receiveObjIds: [],
      msgContent: '', // 要发送的主题内容
      bookmark: [],
      bookmarkDevice: [],
      searchBox: {
        top: 45,
        left: 0
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tParams: {
        actionType: 'T',
        bookmardIds: 'all',
        currentPageNum: 1,
        everyPageNum: 50000,
        online: true,
        offline: true,
        live: true,
        sort: true,
        anywhere: true,
        pack: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        receiver: true,
        token: true,
        noCamera: true,
        unClassified: true,
        alert: true
      },
      filterOptions: [
        'lang.tvuPack',
        'lang.tvuAnywhere',
        'lang.gridPack',
        'lang.gridSDI',
        'lang.receiver',
        'lang.token'
      ],
      filterList: [],
      checkAll: true,
      textareaHeight: 317,
      checkedFilterList: [
        'lang.tvuPack',
        'lang.tvuAnywhere',
        'lang.gridPack',
        'lang.gridSDI',
        'lang.receiver',
        'lang.token'
      ],
      isIndeterminate: false,
      tData: [],
      rData: [],
      tDevicesArr: [],
      isShow: {
        bookmark: false,
        filter: false
      },
      groupContent: [],
      groupDevicesArr: [],
      checkedLength: 0,
      title: '',
      hasSentMsg: false,
      searchVal: '',
      searchObjList: [],
      timer: null,
      totalBookmark: 0
    }
  },
  created () {
    this.filterList = this.filterOptions // filter选项初始化
    this.hasSentMsg = false
  },
  async mounted () {
    $('.sent_alerts .el-dialog').click(() => {
      // 隐藏多选框
      this.isShow.filter = false
      this.isShow.bookmark = false
      // 隐藏搜索列表
      this.searchVal = ''
      this.searchObjList = []
    })
    await this.getTList()
    this.getBookmarkAndDeviceList()

    Bus.$on('langSeltSwitch', (val) => {
      if (!this.$i18n) return
      this.groupDevicesArr[this.groupDevicesArr.length - 1].label = `${this.$t('lang.allDevice')}(${this.groupDevicesArr[this.groupDevicesArr.length - 1].children.length})`
      if (this.groupDevicesArr.length < 2) return
      this.groupDevicesArr[this.groupDevicesArr.length - 2].label = `${this.$t('lang.unClassified')}(${this.groupDevicesArr[this.groupDevicesArr.length - 2].children.length})`
    })
  },
  methods: {
    handleClose () {
      alertAnalyze('alertComposeClose', '/al')
      this.$emit('updateSentMsg', this.isInbox ? 'receive' : 'sent', this.hasSentMsg)
      this.$emit('update:dialogVisible', false)
    },
    // 输入搜索
    searchKeyDown () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.searchSendObject()
      }, 500)
    },
    // 搜索要发送的对象
    searchSendObject () {
      this.searchObjList = []
      if (!this.searchVal) return
      this.bookmarkDevice.map((v) => {
        if (v.name.toLowerCase().includes(this.searchVal.toLowerCase()) && !this.receiveObjIds.includes(v.peerId)) {
          this.searchObjList.push(v)
        }
      })
      this.searchBox.top = this.$refs.address.offsetHeight
      this.searchBox.left = this.$refs.searchDevice.offsetLeft - 26
    },
    // 获取bookmark和device的混合列表
    getBookmarkAndDeviceList () {
      let peerIds = []
      this.groupDevicesArr.map((item) => {
        if (!peerIds.includes(item.peerId) && item.children.length) {
          peerIds.push(item.peerId)
          this.bookmarkDevice.push({
            label: item.label,
            name: item.name,
            peerId: item.peerId,
            children: item.children
          })
        }
        if (item.children.length) {
          item.children.map((v) => {
            if (!peerIds.includes(v.peerId)) {
              peerIds.push(v.peerId)
              this.bookmarkDevice.push({
                groupName: v.groupName,
                label: v.label,
                name: v.name,
                peerId: v.peerId
              })
            }
          })
        }
      })
    },
    // 点击group tree的回调
    handleNodeClick (data, type) {
      if (type) alertAnalyze('alertBookmarkDeviceListClick', '/al')
      else alertAnalyze('alertComposeSearchClick', '/al')
      if (data.children && !data.children.length && !data.groupName) return // 没有子集的bookmark不能发送
      var flag = true
      this.searchObjList = [] // 搜索数组置空
      this.searchVal = '' // 搜索条件置空
      this.receiveMsgArr.map((item) => {
        if (item.peerId == data.peerId) {
          flag = false
          return false
        }
      })
      if (!data.peerId) return
      if (flag) {
        this.receiveMsgArr.push(data)
        this.receiveObjIds.push(data.peerId)
      }
      this.$nextTick(() => {
        const height = $('.sent_member .address').height()
        if (height <= 36) return
        this.textareaHeight = 317 - height + 36
      })
    },
    // 取消接收人
    cancelReceiver (obj) {
      this.receiveMsgArr.map((item, index) => {
        if (item.peerId == obj.peerId) {
          this.receiveMsgArr.splice(index, 1)
          this.receiveObjIds.splice(index, 1)
        }
      })
      this.$nextTick(() => {
        const height = $('.sent_member .address').height()
        if (height <= 36) return
        this.textareaHeight = 317 - height + 36
      })
    },
    // 阻止默认事件
    prevent (e) {
      e.stopPropagation()
    },
    // 获取T列表
    async getTList () {
      await this.axios.post('/ccp/tvucc-alert/source/queryUserSource', this.tParams, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then((res) => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.tData = res.data.result.list
          this.fn_updateTList(this.tData)
          this.handleGroupArr()
        }
      }).catch(() => {})
    },
    // 更新T列表
    fn_updateTList (data) {
      let allTDevice = []
      if (data.length > 0) {
        data.map((tItem) => {
          if (!['Ext', 'YouTube'].includes(tItem.type)) {
            const item = {
              peerId: tItem.peerId,
              name: tItem.name,
              type: tItem.type,
              bookmarks: tItem.bookmarks
            }
            allTDevice.push(item)
          }
        })
      }
      this.tDevicesArr = allTDevice
    },
    handleBookmark (arr) {
      if (arr.length == 0) return
      arr.map((item, index) => {
        this.groupContent.push(item)
        if (item.children.length > 0) {
          this.handleBookmark(item.children)
        }
      })
    },
    // 点击按钮发送消息
    sendMsg () {
      alertAnalyze('alertSentMessageClick', '/al')
      if (this.msgContent.trim() == '') {
        this.$message.error(this.$t('lang.iptNesInfo'))
        return
      }
      if (this.receiveMsgArr.length == 0) {
        this.$message.error(this.$t('lang.addReceiver'))
        return
      }
      let recipients = []
      var recipientsName = []
      this.receiveMsgArr.map((item) => {
        let peerId = item.peerId
        if (!item.groupName) peerId = `@${item.peerId}`
        recipients.push(peerId)
        recipientsName.push(item.name)
      })
      var params = {
        message: this.msgContent.trim(),
        recipients: recipients.join(';'),
        recipientsName: recipientsName.join(';'),
        title: this.title.trim()
      }
      this.axios.post('/ccp/tvucc-alert/message/sendMessageOut ', params, {
        headers: { 'Content-Type': 'application/json;charset=UTF-8' }
      }).then((res) => {
        if (res.status == 200 && res.data.errorCode == '0x0') {
          this.$message.success(this.$t('lang.success'))
          this.hasSentMsg = true
          this.receiveMsgArr = []
          this.receiveObjIds = []
          this.title = ''
          this.msgContent = ''
        } else {
          const errorInfo = this.$t(toTips[res.data.errorCode]) || res.data.errorInfo
          this.$message.error(errorInfo)
        }
      }).catch(() => {})
    },
    // 处理group name
    handleGroupArr () {
      this.groupDevicesArr = []
      const deviceList = JSON.parse(JSON.stringify(this.tData))
      let groupDeviceList = []
      let allDevices = [] // 所有设备
      let notSelectBookId = []
      deviceList.map((item, index) => {
        if (item.type == 'Ext' || item.type == 'YouTube') return true
        if (item.bookmarks && item.bookmarks.length) {
          for (let j = 0; j < item.bookmarks.length; j++) {
            const groupDevice = {
              groupId: item.bookmarks[j],
              label: item.name,
              name: item.name,
              peerId: item.peerId
            }
            groupDeviceList.push(groupDevice)
          }
        }
      })

      this.groupContent.map((item, index) => {
        let deviceArr = []
        if (!item.check) {
          notSelectBookId.push(item.id)
          return
        }
        groupDeviceList.map((v) => {
          if (v.groupId.trim() == item.id.trim()) {
            v.groupName = item.bookmarkName
            deviceArr.push(v)
          }
        })
      })
      this.tDevicesArr.map((v) => {
        let include
        if (v.bookmarks && v.bookmarks.length) {
          include = false
          v.bookmarks.map((id) => {
            if (!notSelectBookId.includes(id)) {
              include = true
            }
          })
        }
        if (include === false) return
        const groupDevice = {
          groupName: 'all device',
          label: v.name,
          name: v.name,
          peerId: v.peerId
        }
        allDevices.push(groupDevice)
      })
      this.groupDevicesArr.push({
        peerId: '',
        label: this.$t('lang.allDevice'),
        name: 'All devices',
        children: allDevices
      })
      // 显示children的length
      this.groupDevicesArr.map((v, i) => {
        v.name = v.label
        if (v.label.length > 8 && i != this.groupDevicesArr.length - 1) {
          v.label = v.label.slice(0, 8) + '...' + ' (' + v.children.length + ')'
        } else {
          v.label = v.label + ' (' + v.children.length + ')'
        }
      })
    },
    // 处理filter多选框
    handleCheckAllChange (val) {
      alertAnalyze('alertComposeFilterClick', '/al')
      this.checkedFilterList = val ? this.filterOptions : []
      this.isIndeterminate = false
      this.changeV3TPar(this.checkedFilterList, 'lang.tvuPack', 'pack')
      this.changeV3TPar(this.checkedFilterList, 'lang.tvuAnywhere', 'anywhere')
      this.changeV3TPar(this.checkedFilterList, 'lang.gridPack', 'gridPack')
      this.changeV3TPar(this.checkedFilterList, 'lang.gridSDI', 'gridSdi')
      this.changeV3TPar(this.checkedFilterList, 'lang.receiver', 'receiver')
      this.changeV3TPar(this.checkedFilterList, 'lang.token', 'token')
      this.getTList()
    },
    handleCheckedCitiesChange (value) {
      alertAnalyze('alertComposeFilterClick', '/al')
      const checkedCount = value.length
      this.checkAll = checkedCount === this.filterList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.filterList.length
      this.changeV3TPar(value, 'lang.tvuPack', 'pack')
      this.changeV3TPar(value, 'lang.tvuAnywhere', 'anywhere')
      this.changeV3TPar(value, 'lang.gridPack', 'gridPack')
      this.changeV3TPar(value, 'lang.gridSDI', 'gridSdi')
      this.changeV3TPar(value, 'lang.receiver', 'receiver')
      this.changeV3TPar(value, 'lang.token', 'token')
      this.getTList()
    },
    changeV3TPar (value, val, key) {
      if (value.includes(val)) this.tParams[key] = true
      else this.tParams[key] = false
    },
    // 是否显示filter
    showFilter () {
      this.isShow.bookmark = false
      if (this.isShow.filter) this.isShow.filter = false
      else this.isShow.filter = true
    },
    changeFilter (val) {
      this.tParams.currentPageNum = 1
      this.tParams.bookmardIds = val.bookmardIds
      this.tParams.unClassified = val.unClassified
      this.getTList()
    }
  }
}
</script>

<style lang='less' scoped>
::v-deep .el-message--error {
  z-index: 20000 !important;
}
::v-deep div[role='group'] {
  .is-leaf.el-tree-node__expand-icon.el-icon-caret-right {
    display: none;
  }
  .el-tree-node .el-tree-node__content .el-tree-node__label {
    padding-left: 33px;
  }
}
::v-deep .el-tree-node .el-tree-node__content .el-tree-node__label {
  width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sent_alerts {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  }
  .setal_content .filter .filter_box .more {
    border-top-width: 0.05rem;
    border-left-width: 0.055rem;
    border-right-width: 0.055rem;
  }
  ::v-deep .el-dialog {
    height: 558px;
    .el-dialog__body {
      padding: 0;
    }
  }
  .setal_content {
    text-align: left;
    height: 90%;
    .user_list {
      width: 287px;
      height: 100%;
    }
    .filter {
      padding-top: 10px;
      padding-bottom: 7px;
      border-bottom: 1px solid #666;
    }
    .filter_box {
      font-size: 12px;
      height: 27px;
      line-height: 27px;
      background: #444;
      width: 128px;
      border-radius: 4px;
      padding-left: 12px;
      cursor: pointer;
      .more {
        position: absolute;
        right: 0.15rem;
        top: 45%;
        border-top-width: 0.12rem;
        border-left-width: 0.07rem;
        border-right-width: 0.07rem;
        border-top-color: #fff;
      }
      ::v-deep .el-checkbox,
      ::v-deep .el-checkbox-group {
        font-size: 12px;
        .el-checkbox__label {
          font-size: 12px;
        }
      }
    }
    .bookmark_box {
      width: 148px;
      padding-left: 0;
    }
    .bookmark_list,
    .filter_list {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0.36rem;
      background: #444;
      z-index: 100;
      border-radius: 6px;
      height: 206px;
      padding-top: 8px;
      padding-bottom: 8px;
      ::v-deep li {
        margin-bottom: 0;
      }
      ::v-deep .bookmark_name {
        width: 70%;
      }
    }
    .el-checkbox {
      display: block;
      margin: 0;
      padding-left: 8px;
      height: 24px;
      line-height: 24px;
    }
    .el-checkbox__label {
      font-size: 12px;
    }
    .list_box {
      padding-top: 0.2rem;
      font-size: 0.14rem;
      height: 420px;
      overflow-y: scroll;
      .el-tree {
        background: transparent;
      }
      .el-tree-node__content {
        height: 30px;
        line-height: 30px;
      }
      .el-tree-node__expand-icon {
        font-size: 19px;
        color: #fff;
      }
      .el-tree-node__label {
        padding-left: 20px;
      }
    }
    .sent_detail {
      width: 623px;
      .sent_member {
        .to:nth-child(2) {
          min-height: 39px;
          max-height: 57px;
          line-height: 39px;
          overflow: hidden;
          margin-bottom: 11px;
          padding: 0;
          input {
            margin-bottom: 0;
            color: #dbdbdb;
          }
        }
        .address {
          input {
            padding-top: 5px;
            margin-left: 6px;
          }
          ul {
            position: absolute;
            left: 0;
            top: 45px;
            padding: 6px 0;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.26);
            background: #444;
            border-radius: 6px;
            font-size: 12px;
            max-height: 230px;
            max-width: 300px;
            overflow-y: scroll;
            li {
              height: 34px;
              line-height: 34px;
              padding: 0 20px;
            }
            li:hover {
              background: rgba(57, 170, 80, 0.25);
            }
          }
        }
        .to {
          color: #dfdfdf;
          font-size: 0.15rem;
          outline: none;
          min-height: 45px;
          max-height: 107px;
          padding-top: 8px;
          border-bottom: 1px solid #666;
          overflow-y: scroll;
          .to_title {
            padding-top: 4px;
          }
          input {
            background: transparent;
            border: none;
            outline: none;
            max-width: 100%;
            min-width: 0;
            color: #dbdbdb;
          }
          p {
            float: left;
            background-color: #444;
            margin: 2px 0 2px 6px;
            padding: 0 8px;
            border-radius: 3px;
            margin-right: 0.05rem;
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            span {
              max-width: 170px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            i {
              cursor: pointer;
              margin-left: 5px;
              color: #808080;
            }
          }
        }
      }
      .messageText {
        min-height: 260px;
        max-height: 322px;
        overflow-y: scroll;
        textarea {
          padding: 6px;
          background-color: #444;
          border-style: none;
          outline: none;
          resize: none;
          color: rgba(128, 128, 128, 1);
          font-size: 14px;
          color: #8d8d8d;
          border-radius: 5px;
          width: 100%;
          line-height: 20px;
          box-sizing: border-box;
        }
      }
      .sent_btn {
        margin-top: 17px;
        button {
          border: 1px solid #39aa50;
          background-color: #39aa50;
          color: #fff;
          cursor: pointer;
          text-align: center;
          color: #fff;
          height: 0.3rem;
          text-decoration: none;
          line-height: 0.3rem;
          min-width: 0.76rem;
          font-size: 0.12rem;
          border-radius: 0.04rem;
          padding: 0 0.05rem;
        }
      }
    }
  }
  .block li .bookmark_name {
    width: 86%;
  }
  ::v-deep .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #fff;
    border-color: transparent;
  }
  ::v-deep .el-checkbox .el-checkbox__inner {
    width: 15px;
    height: 15px;
  }
  ::v-deep .el-checkbox .el-checkbox__inner::after {
    border-color: #33ab4f;
    left: 4px;
    top: 0;
  }
  .block li {
    margin-bottom: 0;
  }
  ::v-deep
    .el-dialog
    .el-dialog__header
    .el-dialog__headerbtn
    .el-icon-close:before {
    content: '\E78D';
  }
}
@media (max-width: 767px) {
  .user_list {
    display: none;
  }
  .sent_alerts .setal_content .sent_detail {
    width: 100%;
  }
}
</style>
<style lang="less">
.sent_alerts.el-dialog__wrapper .el-dialog .el-dialog__header {
  border-bottom: 2px solid #666;
}
@media (max-width: 767px) {
  .sent_alerts .el-dialog {
    width: 100% !important;
    .el-dialog__header {
      padding: 0;
    }
  }
}
</style>
